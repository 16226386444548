import { memo } from 'react';
import PropTypes from 'prop-types';
import QueryContext from './QueryContext';

const AppContext = ({ children, ...props }) => {
  return <QueryContext>{children}</QueryContext>;
};

AppContext.propTypes = {
  children: PropTypes.element,
};

export default memo(AppContext);
