import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { unsubscribeUser } from '../services/unsubscription.service';
import { useLanguageInfo } from '../../../hooks/useLanguageInfo';

export const useUnsubscription = () => {
  const { mutateAsync, isLoading, isError, error } = useMutation(unsubscribeUser);
  const { language, region } = useLanguageInfo();

  const unsubscribe = useCallback(
    async (body) => {
      const email = body?.email;

      await mutateAsync({
        email,
        language,
        country: region,
      });
    },
    [mutateAsync, region, language]
  );

  return { unsubscribe, isLoading, isError, error };
};
