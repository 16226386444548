import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { decryptEmail } from '../services/unsubscription.service';
import { useUnsubscriptionParams } from './useUnsubscriptionParams';

export const useUnsubscriptionInitialData = () => {
  const { email, region, language} = useUnsubscriptionParams();

  const enabled = !!email;

  const { data: rawEmailData, isLoading, isFetching } = useQuery([email], () => decryptEmail({ email }), { enabled });

  const data = useMemo(() => {
    return {
      email: rawEmailData?.result,
      country: region,
      language: language,
    };
  }, [rawEmailData, region, language]);

  console.log(data);

  return { data, isLoading: isLoading && isFetching };
};
