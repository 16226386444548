import { Navigate } from 'react-router-dom';
import UnsubscriptionPage from './unsubscription/pages/UnsubscriptionPage';

export const routes = [
  { index: true, element: <Navigate to="unsubscribe-printables" replace={true} /> },
  {
    path: 'unsubscribe-printables',
    element: <UnsubscriptionPage />,
  },
];
