import { memo } from 'react';
import UnsubscriptionContainer from '../containers/UnsubscriptionContainer';

const UnsubscriptionPage = () => {
  return (
    <div>
      <UnsubscriptionContainer />
    </div>
  );
};

export default memo(UnsubscriptionPage);
