import { useState, useCallback, memo, useEffect } from 'react';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useUnsubscription } from '../../hooks/useUnsubscription';
import SpingLoading from '../../../../components/SpingLoading';
import styles from './styles.module.css';
import { useUnsubscriptionInitialData } from '../../hooks/useUnsubscriptionInitialData';

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

const UnsubscriptionForm = () => {
  const [isSuccess, setIsSuccess] = useState();
  const { t } = useTranslation(['common', 'unsubscription', 'error']);
  const { unsubscribe, isLoading } = useUnsubscription();
  const { isLoading: isLoadingInitialData, data: initialData } = useUnsubscriptionInitialData();

  const disabled = isLoading || isLoadingInitialData;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!isLoadingInitialData && Object.values(initialData).filter(Boolean).length) {
      reset(initialData);
    }
  }, [isLoadingInitialData, initialData, reset]);

  const handleFinish = useCallback(
    async (data) => {
      try {
        await unsubscribe(data);
        reset({ email: '' });
        setIsSuccess(true);
      } catch (error) {}
    },
    [reset, unsubscribe]
  );

  return (
    <div className={styles.container}>
      {isSuccess ? (
        <div>
          <h3>{t('unsubscription:successMessage.title')}</h3>
          <p>{t('unsubscription:successMessage.subTitle')}</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit(handleFinish)}>
          <div className={styles['form-content']}>
            <label htmlFor="email" className={styles.label}>
              {t('common:email')}:
            </label>
            <input id="email" {...register('email')} className={styles['text-field']} disabled={isLoadingInitialData} />
            <div className={styles['form-error-messages']}>
              {!!errors?.email && <span>{t('error:validation.invalidEmail')}</span>}
            </div>
          </div>
          <div className={styles['form-actions']}>
            <button
              type="submit"
              className={cn(styles['submit-button'], { [styles['loading-action']]: isLoading })}
              disabled={disabled}>
              <span>{t('unsubscription:form.submit')}</span>
              {isLoading && (
                <span className={styles['button-loading']}>
                  <SpingLoading />
                </span>
              )}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default memo(UnsubscriptionForm);
