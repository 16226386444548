import PropTypes from 'prop-types';
import styles from './styles.module.css';

const MainLayout = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

MainLayout.propTypes = {
  children: PropTypes.element,
};

export default MainLayout;
