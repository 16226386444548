import { memo, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { getLanguageInfo } from '../utils/languageInfo';
import { useLanguage } from '../hooks/useLanguage';
import { DEFAULT_COUNTRY } from '../constants/locales';

const getScriptPath = (lang) => {
  const { language, region } = getLanguageInfo(lang);
  const country = String(region || DEFAULT_COUNTRY).toLowerCase();
  return `https://www8.hp.com/caas/header-footer/${country}/${language}/default/latest.r?contentType=js`;
};

const NavigationSectionContainer = () => {
  const language = useLanguage();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const jsPath = useMemo(() => getScriptPath(language), []);

  return (
    <Helmet>
      <script src={jsPath} type="text/javascript" />
    </Helmet>
  );
};

export default memo(NavigationSectionContainer);
