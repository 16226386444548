import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DEFAULT_COUNTRY, DEFAULT_LANGUAGE } from '../constants/locales';

const InternationalizationContainer = ({ children }) => {
  const { i18n } = useTranslation();
  const { search } = useLocation();

  const params = useMemo(() => {
    try {
      return Array.from(new URLSearchParams(search).entries()).reduce(
        (result, [key, value]) => ({ ...result, [key]: value }),
        {}
      );
    } catch (error) {
      return {};
    }
  }, [search]);

  const lang = useMemo(() => {
    const { language = DEFAULT_LANGUAGE, country = DEFAULT_COUNTRY } = params;

    return [language && String(language).toLowerCase(), country && String(country).toLocaleUpperCase()]
      .filter(Boolean)
      .join('-');
  }, [params]);

  useEffect(() => {
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return <>{children}</>;
};

InternationalizationContainer.propTypes = {
  children: PropTypes.element,
};

export default InternationalizationContainer;
