import { memo } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import ContainerLayout from './layout/ContainerLayout';
import { routes as subRoutes } from './modules';

const routes = [
  { path: '/', element: <Navigate to={'/unsubscribe-printables'} replace={true} /> },
  {
    path: '*',
    element: <ContainerLayout />,
    children: [...subRoutes],
  },
];

const AppRouting = () => {
  const element = useRoutes(routes);

  return element;
};

export default memo(AppRouting);
