import { memo, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import PropTypes from 'prop-types';

export const CONFIG = {};

const QueryContext = ({ children }) => {
  const [queryClient] = useState(() => new QueryClient(CONFIG));

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

QueryContext.propTypes = {
  children: PropTypes.element,
};

export default memo(QueryContext);
