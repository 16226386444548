import { memo } from 'react';
import MainLayout from './layout/MainLayout';
import AppRouting from './AppRouting';
import AppContext from './contexts/AppContext';

const App = () => {
  return (
    <AppContext>
      <MainLayout>
        <AppRouting />
      </MainLayout>
    </AppContext>
  );
};

export default memo(App);
