import { useMemo } from 'react';
import { useLanguage } from './useLanguage';
import { getLanguageInfo } from '../utils/languageInfo';

export const useLanguageInfo = () => {
  const language = useLanguage();

  return useMemo(() => {
    return getLanguageInfo(language);
  }, [language]);
};
