import { useTranslation } from 'react-i18next';
import UnsubscriptionForm from '../components/UnsubscriptionForm';

const UnsubscriptionContainer = () => {
  const { t } = useTranslation('unsubscription');

  return (
    <div>
      <h1>{t('title')}</h1>
      <UnsubscriptionForm />
    </div>
  );
};

export default UnsubscriptionContainer;
