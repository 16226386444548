import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import NavigationSectionContainer from '../../containers/NavigationSectionContainer';
import InternationalizationContainer from '../../containers/InternationalizationContainer';

const ContainerLayout = () => {
  return (
    <>
      <InternationalizationContainer>
        <Outlet />
      </InternationalizationContainer>
      <NavigationSectionContainer />
    </>
  );
};

export default memo(ContainerLayout);
