import tags from 'language-tags';

export function getLanguageInfo(lang) {
  let language, region;
  

  try {
    const _tags = tags(lang);

    language = _tags.language()?.data?.record?.Subtag;

    region = _tags.region()?.data?.record?.Subtag;
  } catch (error) {
    // do not thing
  }

  return { language, region };
}
