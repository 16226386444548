import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useUnsubscriptionParams = () => {
  const { search } = useLocation();

  return useMemo(() => {
    const qp = new URLSearchParams(search);

    const email = qp.get('e');
    const region = qp.get('country');
    const language = qp.get('language');


    return {
      email,
      region,
      language
    };
  }, [search]);
};
